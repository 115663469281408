import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { take } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class CourseService {
  private coursesSubject: BehaviorSubject<any[]> = new BehaviorSubject([]);
  private coursesLoadingSubject: BehaviorSubject<boolean> = new BehaviorSubject(false);
  private coursesErrorSubject: BehaviorSubject<string> = new BehaviorSubject(null);
  private selectedCourseSubject: BehaviorSubject<any> = new BehaviorSubject(null);
  private selectedCourseLoadingSubject: BehaviorSubject<boolean> = new BehaviorSubject(false);
  private selectedCourseErrorSubject: BehaviorSubject<string> = new BehaviorSubject(null);

  get courses(): Observable<any[]> {
    return this.coursesSubject.asObservable();
  }

  get coursesLoading(): Observable<boolean> {
    return this.coursesLoadingSubject.asObservable();
  }

  get coursesError(): Observable<string> {
    return this.coursesErrorSubject.asObservable();
  }

  get selectedCourse(): Observable<any> {
    return this.selectedCourseSubject.asObservable();
  }

  get selectedCourseLoading(): Observable<boolean> {
    return this.selectedCourseLoadingSubject.asObservable();
  }

  get selectedCourseError(): Observable<string> {
    return this.selectedCourseErrorSubject.asObservable();
  }

  constructor(private http: HttpClient) { }

  fetchCourse() {
    this.coursesErrorSubject.next(null);
    this.coursesLoadingSubject.next(true);
    this.http.get('https://api.1apisolution.com/v1/courses/zotech').subscribe(
      (courses: any[]) => {
        this.coursesSubject.next(courses);
        this.coursesLoadingSubject.next(false);
      },
      (error: any) => {
        this.coursesErrorSubject.next(error.response.message);
        this.coursesLoadingSubject.next(false);
      }
    );
  }

  getCourseDetails(courseId: string): void {
    this.selectedCourseLoadingSubject.next(true);
    if (this.coursesSubject.value.length > 0) {
      const courseDetails = this.coursesSubject.value.find((c: any) => c.id === courseId);
      if (courseDetails) {
        this.selectedCourseSubject.next(courseDetails);
        this.selectedCourseLoadingSubject.next(false);
      } else {
        this.selectedCourseErrorSubject.next('not found');
        this.selectedCourseLoadingSubject.next(false);
      }
    } else {
      this.http.get('https://api.1apisolution.com/v1/courses/zotech').subscribe(
      (courses: any[]) => {
        this.coursesSubject.next(courses);
        const course = courses.find((c: any) => c.id === courseId);
        if (course) {
          this.selectedCourseSubject.next(course);
          this.selectedCourseLoadingSubject.next(false);
        } else {
          this.selectedCourseErrorSubject.next('not found');
          this.selectedCourseLoadingSubject.next(false);
        }
      },
      (error: any) => {
        this.coursesErrorSubject.next(error.response.message);
        this.coursesLoadingSubject.next(false);
      }
    );
    }
  }
}
