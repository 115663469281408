import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NotifierService } from 'src/app/notifier.service';
import { ReplaySubject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-confirmation',
  templateUrl: './confirmation.component.html',
  styleUrls: ['./confirmation.component.scss'],
})
export class ConfirmationComponent implements OnInit, OnDestroy {
  private destroyer$: ReplaySubject<any> = new ReplaySubject();
  data: any = null;
  constructor(
    private route: Router,
    private _router: ActivatedRoute,
    private notifier: NotifierService
  ) {}

  ngOnInit(): void {
    this.notifier.paymentConfirmation
      .pipe(takeUntil(this.destroyer$))
      .subscribe((evt) => {
        this.data = evt;
      });
      if (this.data === null) {
        this.route.navigate(['/']);
      }
  }

  ngOnDestroy() {
    this.destroyer$.next(true);
    this.destroyer$.complete();
    this.notifier.sendNotification('PAYMENT_CONFIRMATION', null);
  }
}
