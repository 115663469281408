import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-location-map',
  templateUrl: './location-map.component.html',
  styleUrls: ['./location-map.component.scss'],
})
export class LocationMapComponent implements OnInit {
  latitude = 38.94816;
  longitude = -76.83351;
  constructor() {}

  ngOnInit(): void {}
}
