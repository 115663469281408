import { Component, OnInit } from '@angular/core';
import { NotifierService } from 'src/app/notifier.service';
import { takeUntil } from 'rxjs/operators';
import { Observable, ReplaySubject } from 'rxjs';
import { Router } from '@angular/router';
import { CourseService } from '../../course.service';


@Component({
  selector: 'app-course-list',
  templateUrl: './course-list.component.html',
  styleUrls: ['./course-list.component.scss']
})
export class CourseListComponent implements OnInit {
  private destroyer$: ReplaySubject<boolean> = new ReplaySubject();
  courseLoading: Observable<boolean>;
  courseError: Observable<string>;
  coursesList: Observable<any[]>;

  constructor(
    private _notifier: NotifierService,
    private _router: Router,
    private coursesService: CourseService
  ) {
    this.courseLoading = this.coursesService.coursesLoading;
    this.courseError = this.coursesService.coursesError;
    this.coursesList = this.coursesService.courses;
  }

  ngOnInit(): void {
    this._notifier.notifier.pipe(takeUntil(this.destroyer$)).subscribe((val) => {
      if (val.name === 'SELECTED_LIST_COURSE') {
        this._router.navigate([`/courses/${val.data.groupId}/${val.data.courseId}`]);
      } else {
        console.warn('action not supported');
      }
    });

    this.coursesService.fetchCourse();
  }

}
