import { Component, OnInit, OnDestroy } from '@angular/core';
import 'aos/dist/aos.css';
import { NotifierService } from 'src/app/notifier.service';
import { Observable, ReplaySubject } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';
import { Router } from '@angular/router';
import { CourseService } from '../../../courses/course.service';

@Component({
  selector: 'app-homepage',
  templateUrl: './homepage.component.html',
  styleUrls: ['./homepage.component.scss'],
})
export class HomepageComponent implements OnInit, OnDestroy {
  private destroyer$: ReplaySubject<boolean> = new ReplaySubject();
  courseLoading: Observable<boolean>;
  courseError: Observable<string>;
  coursesList: Observable<any[]>;
  allCourses: any[] = [];

  loadingCourse = false;
  displayedCourses: any[] = [];
  displayedGroupId: string = null;
  activeGroup = "topClasses";
  courseGroups: any[] = [
    {
      id: 'topClasses',
      name: 'Top Classes',
    },
    {
      id: 'group2',
      name: 'CompTIA',
    },
    {
      id: 'aws',
      name: 'AWS',
    },
    {
      id: 'combos',
      name: 'Combo Courses',
    },
  ];

  about: string[] = [
    'I.T Training School offers a production grade sandbox environment' +
      ' where students are able to take advantage of the latest technology' +
      'through hands-on assignments, projects, and tailored classes that ' +
      'simplify the material through visualizing the information.',
    'Our 12-Week Linux Systems Administrator and other training program is designed to ' +
      'instruct by shaping your experience through resolving complex system and ' +
      'network issues while learning the relevant skills needed to enter the job' +
      'market with valuable and marketable skills needed to compete.',
    'Our dynamic teaching approach incorporates visual and practical theories' +
      'discussions, and interactive lessons in order to educate and inspire.',
  ];
  constructor(
    private notifier: NotifierService,
    private router: Router,
    private coursesService: CourseService
  ) {
    this.courseLoading = this.coursesService.coursesLoading;
    this.courseError = this.coursesService.coursesError;
    this.coursesList = this.coursesService.courses;
  }

  ngOnInit(): void {
    this.coursesService.fetchCourse();

    this.coursesList.pipe(takeUntil(this.destroyer$)).subscribe(
      (courses: any[]) => {
        this.allCourses = courses;
        this.changeCourseGroup(this.activeGroup);
        // Here you have to go thru the list of courses and extract the group.
      },
      (error: any) => {
        // Here u habdle the error in case the backend is fucked up.
      }
    );

    this.notifier.notifier
      .pipe(
        takeUntil(this.destroyer$),
        filter((val) =>
          ['TAB_OPTION_CLICK', 'SELECTED_COURSE'].includes(val.name)
        )
      )
      .subscribe((val) => {
        if (val.name === 'TAB_OPTION_CLICK') {
          this.changeCourseGroup(val.data);
        } else if (val.name === 'SELECTED_COURSE') {
          this.router.navigate(['/courses', this.displayedGroupId, val.data]);
        } else {
          console.warn('Action not supported');
        }
      });
  }

  private changeCourseGroup(event: string): void {
    this.activeGroup = event;
    this.displayedCourses = this.allCourses.filter((course: any) => course.groupId === event);
  }

  ngOnDestroy() {
    this.destroyer$.next(true);
    this.destroyer$.complete();
  }
}
