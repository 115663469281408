<div class="w-100 d-flex justify-content-around flex-wrap">
  <div *ngFor="let tabOption of tabOptions" class="m-3 res-tab">
    <!-- set each tab option here -->
    <div
      class="tab"
      (click)="onTabActionClick(tabOption.id)"
      [ngClass]="{ active: isTabSelected(tabOption.id) }"
    >
      {{ tabOption.name }}
    </div>
  </div>
</div>
