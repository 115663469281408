<section
  *ngIf="!isItAllList"
  class="w-100 black-text p-4 bg-white course-inner-card mat-elevation-z4"
  (click)="onCourseClick(course.id)"
>
  <div class="w-100 card-h d-flex">
    <div class="card-img">
      <img [src]="course.logo" />
    </div>
    <label class="logo-title pl-3 pr-3">{{ course.courseTitle }}</label>
  </div>
  <div class="w-100 card-body no-padding">
    <div class="w-100 card-title large font-weight-light">
      {{ course.courseTitle }}
    </div>
    <p class="body-description">
      {{ course.courseDescription.substr(0, 100) }}...
    </p>
  </div>
  <div class="card-actions pl-2">
    <a class="large">{{ "learn more" | titlecase }}</a>
  </div>
</section>

<section
  *ngIf="isItAllList"
  class="w-100 black-text p-4 bg-white course-list-card mat-elevation-z4"
  (click)="onListCourseSelect(course.groupId, course.id)"
>
  <div class="w-100 card-h d-flex">
    <div class="card-img">
      <img [src]="course.logo" />
    </div>
    <label class="logo-title p-3">{{ course.courseTitle }}</label>
  </div>
  <div class="w-100 card-list-body no-padding">
    <div class="w-100 card-title x-title font-weight-light">
      {{ course.courseTitle }}
    </div>
    <p class="body-description">
      {{ course.courseDescription.substr(0, 250) }}...
    </p>
  </div>
  <div class="w-100 card-actions pl-2">
    <a class="large">{{ "learn more" | titlecase }}</a>
  </div>
</section>
