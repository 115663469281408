import { Component, OnInit, Input } from '@angular/core';
import { NotifierService } from 'src/app/notifier.service';

@Component({
  selector: 'app-tab-controller',
  templateUrl: './tab-controller.component.html',
  styleUrls: ['./tab-controller.component.scss'],
})
export class TabControllerComponent implements OnInit {
  @Input() tabOptions: any[] = [];
  selectedTabId: string = null;

  constructor(private notifier: NotifierService) {}

  ngOnInit(): void {
    // just for sanity check, we will automatically check the first element of the tabs
    if (this.tabOptions.length > 0) {
      this.selectedTabId = this.tabOptions[0].id;
      this.onTabActionClick(this.selectedTabId);
    }
  }

  onTabActionClick(tabId: string) {
    // just emit the action
    this.notifier.sendNotification('TAB_OPTION_CLICK', tabId);

    // set the selected tab
    this.selectedTabId = tabId;
  }

  isTabSelected(tabId: string) {
    return this.selectedTabId === tabId;
  }
}
