export const environment = {
  production: true,
  newLetterToken: 'zo-5df023d60670445089da43e0369e6cb1-tech',
  stripeApiKey:
    'pk_test_51HOstqBmx2r5mXPMzp4GbTxPIleHL2mLxAdZo9lHoXzlpFvw2MsMigfJaozIkUo3bkHWr593teQXrSsi5zqdBwt300uDBzjPtn',
  amgApiKey: 'AIzaSyBP9sh7axzz3VNFuVwcBnCxgT0_hN3rOE8',
  emailService: {
    protocol: 'https',
    url: 'api.hintekkllc.com/v1/mailer',
    token: 'Hintekk-ITTrainingSchool',
  },
  payment: {
    square: {
      squareApplicationId: 'sq0idp-xzTBGNTrtHxSy-VDrxxUpA',
      squareAccessToken:
        'EAAAEObHHmPvxy182pdgCk68EsADZ31ronTZZWyi0s_oHpRuhQQx_NlZNcnEYO6v',
      protocol: 'https',
      url: 'api.1apisolution.com/v1/payments/square',
      customer: {
        url: 'api.1apisolution.com/v1/payments/customers?customer_id',
      },
      params: {
        customer_id: 'ittrainingschool',
        environment: 'production',
      },
    },
  },
};
