import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-about-page',
  templateUrl: './about-page.component.html',
  styleUrls: ['./about-page.component.scss']
})
export class AboutPageComponent implements OnInit {

  aboutUsList = [
    {
      logo: '../../../../assets/logo/zologo_backup.jpeg',
      title: '12-Week Full-Time Career Accelerator',
      details: 'Our Intensive and Structured Programs provide the skill sets to launchpad you into earning six figures per year.'
    },
    {
      logo: '../../../../assets/logo/zologo_backup.jpeg',
      title: 'learn from engineers',
      details: 'receive instruction and mentorship from engineers already in the field as we continue to build our community of professionals and peers.'
    },
    {
      logo: '../../../../assets/logo/zologo_backup.jpeg',
      title: 'solve real problem',
      details: 'Gain key industry knowledge and specialized skills by solving real problems on a real production network.'
    },
    {
      logo: '../../../../assets/logo/zologo_backup.jpeg',
      title: 'become interview ready',
      details: 'Our in-house career coaches provide resources and guidance to get you on track for an exciting career in technology.'
    },
    {
      logo: '../../../../assets/logo/zologo_backup.jpeg',
      title: 'start an intership with us',
      details: 'Our Intensive and Structured Programs provide the skill sets to launchpad you into earning six figures per year.'
    },
    {
      logo: '../../../../assets/logo/zologo_backup.jpeg',
      title: 'join our community of practice',
      details: 'Learn best practices from other students, graduates, and experts already in the field. Learn new knowledge to advance ' 
      + 'your professional career through regular interaction and exposure to complex system issues.'
    }
  ]
  constructor() { }

  ngOnInit(): void {
  }

}
