import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Router } from '@angular/router';
import { NotifierService } from 'src/app/notifier.service';

export interface PackageComparator {
  title: string;
  details: string[];
}
export interface CourseCard {
  id: string;
  groupId?: string;
  logo: string;
  image?: string;
  price: number;
  endDate: string;
  category: string;
  duration: string;
  location: string[];
  beginsDate: string;
  courseTitle: string;
  trainingDay: string;
  trainingType: string;
  trainingTime: string;
  contactInfo: string[];
  courseOutcomes: string[];
  courseDescription: string;
  trainingBenefits: string[];
  packageComparators: PackageComparator[];
}
export interface CourseGroup {
  name: string;
  id: string;
  courses: CourseCard[];
}
@Component({
  selector: 'app-course-card',
  templateUrl: './course-card.component.html',
  styleUrls: ['./course-card.component.scss'],
})
export class CourseCardComponent implements OnInit {
  @Input() course: CourseCard = null;
  @Input() isItAllList: boolean = false;

  constructor(private notifier: NotifierService, private _router: Router) {}

  ngOnInit(): void {}

  onCourseClick(courseId: string): void {
    this._router.navigate(['/courses', this.course.groupId, this.course.id]);
  }
  onListCourseSelect(groupId: string, courseId: string) {
    this.notifier.sendNotification('SELECTED_LIST_COURSE', {
      groupId,
      courseId,
    });
  }
}
