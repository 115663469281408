import { NgModule } from '@angular/core';
import { AgmCoreModule } from '@agm/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { AlertComponent } from './components/alert/alert.component';
import { WtiHeaderComponent } from './components/wti-header/wti-header.component';
import { WtiMaterialsModule } from '../wti-materials/wti-materials.module';
import { CourseCardComponent } from './components/course-card/course-card.component';
import { TabControllerComponent } from './components/tab-controller/tab-controller.component';
import { ContactCardComponent } from './components/contact-card/contact-card.component';
import { LocationMapComponent } from './components/location-map/location-map.component';
import { WtiFooterComponent } from './components/wti-footer/wti-footer.component';
import { CheckoutSummaryComponent } from './components/checkout-summary/checkout-summary.component';

@NgModule({
  declarations: [
    WtiHeaderComponent,
    CourseCardComponent,
    TabControllerComponent,
    ContactCardComponent,
    LocationMapComponent,
    WtiFooterComponent,
    CheckoutSummaryComponent,
    AlertComponent,
  ],
  imports: [CommonModule, WtiMaterialsModule, AgmCoreModule, RouterModule],
  exports: [
    AlertComponent,
    WtiHeaderComponent,
    WtiFooterComponent,
    CourseCardComponent,
    ContactCardComponent,
    TabControllerComponent,
    CheckoutSummaryComponent,
  ],
})
export class WtiSharesModule {}
