import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import AOS from 'aos';
import 'aos/dist/aos.css';
import {
  ButtonsModule,
  WavesModule,
  CollapseModule,
  ModalModule,
} from 'angular-bootstrap-md';
import { AgmCoreModule } from '@agm/core';
import {MatDialogModule} from '@angular/material/dialog';
import { ReactiveFormsModule } from '@angular/forms';
import {MatSelectModule} from '@angular/material/select';
import { MatRadioModule } from '@angular/material/radio';
import { MatInputModule } from '@angular/material/input';
import { MatStepperModule } from '@angular/material/stepper';
import { MatButtonModule } from '@angular/material/button';
import {MatDividerModule} from '@angular/material/divider';
import { BadgeModule, IconsModule } from 'angular-bootstrap-md';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatIconModule } from '@angular/material/icon';

@NgModule({
  declarations: [],
  imports: [CommonModule, ButtonsModule, CollapseModule, WavesModule, MatDialogModule, MatIconModule],
  exports: [
    ModalModule,
    WavesModule,
    ButtonsModule,
    MatInputModule,
    CollapseModule,
    AgmCoreModule,
    BadgeModule,
    MatIconModule,
    MatRadioModule,
    MatButtonModule,
    MatDialogModule,
    MatSelectModule,
    MatStepperModule,
    MatDividerModule,
    MatExpansionModule,
    ReactiveFormsModule,
  ],
})
export class WtiMaterialsModule {}
