<section class="w-100 page-container">
  <section class="w-100 homepage position-relative">
    <div class="w-100 position-absolute banner p-5 text-center">
      <p class="animated slideInLeft black-text">
        {{
          "let I.T Training School jump start you into your new I.T career"
            | uppercase
        }}
      </p>
      <h1 class="animated slideInRight main-color">
        {{ "start your quest" | uppercase }}
      </h1>
    </div>
  </section>
  <section class="w-100 p-5 bg-white course-list-container">
    <h3 class="w-100 black-text text-center">
      {{ "Check Out Hight Demand I.T Certication Courses Around DMV Area" }}
    </h3>
    <div class="w-100">
      <div class="w-100 d-inline-flex justify-content-center">
        <app-tab-controller [tabOptions]="courseGroups"></app-tab-controller>
      </div>

      <!-- display the courses that needs to be displayed -->
      <section class="w-100 d-flex flex-wrap justify-content-center">
        <div class="course-card m-3" *ngFor="let course of displayedCourses">
          <app-course-card [isItAllList]="false" [course]="course"></app-course-card>
        </div>
      </section>
    </div>
  </section>
  <section class="w-100 about-section p-5 text-center">
    <div class="w-75 mx-auto about-inner-wrap">
      <h1 class="black-text">{{ "about us" | uppercase }}</h1>
      <p class="black-text w-75 mx-auto description" *ngFor="let text of about">
        {{ text }}
      </p>
      <button type="button" mdbBtn class="bg-color text-white" mdbWavesEffect routerLink="/about-us">
        read more
      </button>
    </div>
  </section>
  <section>
    <app-contact-card></app-contact-card>
  </section>
</section>
