import { Injectable, EventEmitter } from '@angular/core';
import { ReplaySubject } from 'rxjs';

export interface NotifierEvent {
  name: string;
  data: any;
}

@Injectable({
  providedIn: 'root',
})
export class NotifierService {
  // main notifier
  private notifierSubject: EventEmitter<NotifierEvent> = new EventEmitter();
  private paymentConfirmationSubject: ReplaySubject<any> = new ReplaySubject(1);

  get notifier() {
    return this.notifierSubject.asObservable();
  }

  get paymentConfirmation() {
    return this.paymentConfirmationSubject.asObservable();
  }

  constructor() {}

  // Broadcast the event across the application.
  sendNotification(name: string, data: any) {
    if (name === 'PAYMENT_CONFIRMATION') {
      this.paymentConfirmationSubject.next(data);
    } else {
      this.notifierSubject.next({ name, data });
    }
  }
}
