import { Router, ActivatedRoute } from '@angular/router';
import { Component, OnInit, Input } from '@angular/core';

export interface HeaderType {
  url: string;
  friendly: string;
}

@Component({
  selector: 'app-wti-header',
  templateUrl: './wti-header.component.html',
  styleUrls: ['./wti-header.component.scss'],
})

export class WtiHeaderComponent implements OnInit {
  @Input() headerMenus: HeaderType[] = [];

  constructor() {}

  ngOnInit(): void {
  }
}
