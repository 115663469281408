import { Component, Input, OnInit } from '@angular/core';

export interface TransactionSummary {
  tax: number;
  total: number;
  amount: number;
  package: string;
  courseName: string;
  costRecovery: number;
  processingFees: number;
  remainingBalance: number;
}

@Component({
  selector: 'app-checkout-summary',
  templateUrl: './checkout-summary.component.html',
  styleUrls: ['./checkout-summary.component.scss']
})
export class CheckoutSummaryComponent implements OnInit {

  @Input() summary: TransactionSummary = null

  constructor() { }

  ngOnInit(): void {
  }

}
