<div class='login-page' fxLayout="column" fxLayoutAlign="center center" fxFlex>
  <!-- <h1>Welcome back</h1> -->
  <mat-card>
      <mat-card-header fxLayout='column' fxLayoutAlign='center center'>
          <mat-card-title>CHECK IN</mat-card-title>
          <mat-card-subtitle fxLayoutAlign="center center">
              Please fill out the form below to receive all new information about our institution.
          </mat-card-subtitle>
      </mat-card-header>
      <!-- <img matCardImage src="Card wide image source"> -->
      <mat-card-content>
          <form [formGroup]="form">
              <formly-form [model]="model" [fields]="fields" [options]="options" [form]="form"></formly-form>
          </form>
      </mat-card-content>
      <mat-card-actions align="center">
          <button mat-raised-button (click)="submitForm()" type='submit' color='primary' [disabled]='loading' fxFlex>Subscribe</button>
      </mat-card-actions>
  </mat-card>
</div>
