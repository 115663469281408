<section
  class="w-100 confirmation-container position-relative background-image-config text-white text-center"
>
  <div
    class="w-100 d-flex justify-content-end position-absolute confirm-inner-wrap"
  >
    <div class="content-wrap mat-elevation-z10 p-5" *ngIf="data">
      <h5 class="large main-color font-weight-bold">
        {{"I.T Training School Welcome You "}}
      </h5>
      <p>
        {{
          "You have successfully made " +
            data.payment.note +
            "."
        }}
      </p>
      <p>
        {{"We will contact you to schedule your one on one with your academy advisor and make your other payment schedule"}}
      </p>
      <a class="main-color" routerLink="/">{{'return home' | uppercase}}</a>
    </div>
  </div>
</section>
