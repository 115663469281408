<div class="w-100 pl-1 pr-1 pb-2 pt-2 mt-4 mb-5 mat-elevation-z10 bg-white-50" *ngIf="summary !== null">
  <mat-label class="d-flex flex-wrap justify-content-between p-2">Enrolled Course:
    <span>{{ summary.courseName | titlecase}}</span></mat-label>
  <mat-divider></mat-divider>
  <mat-label class="d-flex flex-wrap justify-content-between p-2">Course Package:
    <span>{{ summary.package | titlecase}}</span></mat-label>
  <mat-divider></mat-divider>
  <mat-label class="d-flex flex-wrap justify-content-between  p-2">{{ "Amount Deposit: " | titlecase }}
    <span>{{summary.amount | currency}}</span></mat-label>
  <mat-divider></mat-divider>
  <mat-label class="d-flex justify-content-between  p-2">{{ "tax: " | titlecase }}
    <span>{{summary.tax | currency}}</span></mat-label>
  <mat-divider></mat-divider>
  <mat-label class="d-flex justify-content-between  p-2">{{ "processing fees: " | titlecase }}
    <span>{{summary.processingFees | currency}}</span></mat-label>
  <mat-divider></mat-divider>
  <mat-label class="d-flex justify-content-between p-2">{{ "resource fees: " | titlecase }}
    <span>{{summary.costRecovery | currency}}</span></mat-label>
  <mat-divider></mat-divider>
  <mat-label class="d-flex flex-wrap justify-content-between  font-weight-bold bg-danger text-white p-3">
    {{ "Total Due:" | titlecase }} <span>{{summary.total | currency}}</span></mat-label>
  <mat-divider></mat-divider>
  <mat-label class="d-block"><span class="font-weight-bold">{{'important notes' | uppercase}}: </span>Remaining balances
    are not subject to be pay now. You would be billed during your course section.</mat-label>
</div>
