import { HttpClient } from '@angular/common/http';
import { FormGroup } from '@angular/forms';
import { Component, OnInit } from '@angular/core';
import { FormlyFieldConfig, FormlyFormOptions } from '@ngx-formly/core';
import { environment } from 'src/environments/environment.prod';
import {MatSnackBar} from '@angular/material/snack-bar';

@Component({
  selector: 'app-newletter',
  templateUrl: './newletter.component.html',
  styleUrls: ['./newletter.component.scss']
})
export class NewletterComponent implements OnInit {
  form = new FormGroup({});
  model: any = {};
  options: FormlyFormOptions = {};
  fields: FormlyFieldConfig[] = [
    {
      key: 'firstName',
      type: 'input',
      templateOptions: {
        label: 'First Name',
        placeholder: 'First Name',
        required: true
      }
    },
    {
      key: 'lastName',
      type: 'input',
      templateOptions: {
        label: 'Last Name',
        placeholder: 'Last Name',
        required: true
      }
    },
    {
      key: 'phoneNumber',
      type: 'input',
      templateOptions: {
        label: 'Phone Number',
        placeholder: 'Phone Number',
        required: true
      }
    },
    {
      key: 'email',
      type: 'input',
      templateOptions: {
        label: 'Email',
        placeholder: 'Email'
      }
    }
  ];
  loading = false;

  constructor(
    private http: HttpClient,
    private snackBar: MatSnackBar
  ) { }

  ngOnInit(): void {
  }

  submitForm() {
    if (this.form.valid) {
      this.loading = true;
      const data = {...this.model, companyId: 'zotech'};
      this.http.post(`https://api.1apisolution.com/v1/newsletter/zotech?token=${environment.newLetterToken}`, data).subscribe(
        res => {
          this.snackBar.open('User was added to the newsletter', 'OK', {
            duration: 2000,
          });
          this.loading = false;
          this.form.reset();
        },
        error => {
          this.snackBar.open(error.response.message, 'OK', {
            duration: 2000,
          });
          this.loading = false;
        }
      );
    }
  }

}
