import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

if (environment.production) {
  enableProdMode();
}

platformBrowserDynamic().bootstrapModule(AppModule)
  .catch(err => console.error(err));


if (environment.production) {
  document.write('<script type="text/javascript" src="https://js.squareup.com/v2/paymentform"></script>');
} else {
  document.write('<script type="text/javascript" src="https://js.squareupsandbox.com/v2/paymentform"></script>');
}
declare global {
  interface Window {
    SqPaymentForm: any;
  }
}
