// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  newLetterToken: 'zo-5df023d60670445089da43e0369e6cb1-tech',
  paypay:
    'AbBPfAnGwiIDoc2u60hT8ARUFVrp6LvZRbVBZBNsvvUAbO66NNHIaEduOMRFPxJDvOxvKvwZtCkYGXfW',
  amgApiKey: 'AIzaSyBP9sh7axzz3VNFuVwcBnCxgT0_hN3rOE8',
  emailService: {
    protocol: 'https',
    url: 'api.hintekkllc.com/v1/mailer',
    token: 'Hintekk-ITTrainingSchool',
  },
  payment: {
    square: {
      squareApplicationId: 'sandbox-sq0idb-EminwXZE9b21nI9mYpLw1A',
      squareAccessToken: 'EAAAELcpS6WPwePAMBi1R2P2MWimAD_1n3T1WLPf-rRBso0GYUNofiUrO1frah86',
      protocol: 'https',
      url: 'api.1apisolution.com/v1/payments/square',
      customer: {
        url: 'api.1apisolution.com/v1/payments/customers?customer_id',
      },
      params: {
        customer_id: 'ittrainingschool',
        environment: 'sandbox',
      },
    },
  },
  stripeApiKey:
    'pk_test_51HOstqBmx2r5mXPMzp4GbTxPIleHL2mLxAdZo9lHoXzlpFvw2MsMigfJaozIkUo3bkHWr593teQXrSsi5zqdBwt300uDBzjPtn',
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
