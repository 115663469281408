import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgxPayPalModule } from 'ngx-paypal';
import { CoursesRoutingModule } from './courses-routing.module';
import { CourseViewerComponent } from './components/course-viewer/course-viewer.component';
import { WtiMaterialsModule } from 'src/app/wti-materials/wti-materials.module';
import { WtiSharesModule } from 'src/app/wti-shares/wti-shares.module';
import { ConfirmationComponent } from './components/confirmation/confirmation.component';
import { CourseListComponent } from './components/course-list/course-list.component';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';

@NgModule({
  declarations: [CourseViewerComponent, ConfirmationComponent, CourseListComponent],
  imports: [
    CommonModule,
    NgxPayPalModule,
    WtiSharesModule,
    WtiMaterialsModule,
    MatProgressSpinnerModule,
    CoursesRoutingModule,
  ],
})
export class CoursesModule {}
