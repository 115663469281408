<div class="contact-card-wrap text-white">
  <div class="w-100 d-block bg-color p-5 position-relative title-section">
    <h1 class="m-0 p-5">get in <span class="black-text">touch</span></h1>
    <span class="title-bg p-5">contact</span>
  </div>
  <div
    class="p-5 d-flex flex-wrap w-100 justify-content-between res-map-wrapper"
  >
    <div class="contact-map">
      <app-location-map></app-location-map>
      <div class="w-100 black-text p-4 res-map-detail">
        <p class="w-100 font-weight-bold">Address:</p>
        <label>10001 Derekwood Ln., Lanham, MD 20706 Suite: 205</label>
        <p class="w-100 font-weight-bold">Have a quick question?:</p>
        <label class="w-100"
          >ZoTech Organization Service Info Line
          <a href="tel:+12406058626">+1 (240) 770 - 8960</a> <br />
          I.T Training School Admission:
          <a href="tel:+12407706366">+1 (240) 770 - 6366</a></label
        >
        <label class="w-100"
          >I.T. Training School Admission Ofc.:
          <a href="tel:+12406058960">+1 (240) 770 - 6366</a> <br />
          ZoTech Technical Support:
          <a href="tel:+12023520946">+1 (240) 770 - 6366</a>
        </label>
      </div>
    </div>
    <!-- <div class="w-50 form-wrapper p-5">
      <form [formGroup]="contactForm" (ngSubmit)="submit()">
        <div class="user-info">
          <div class="md-form">
            <input
              mdbInput
              placeholder="Name*"
              type="text"
              id="userName"
              class="form-control"
              formControlName="visitorName"
            />
            <label for="userName"></label>
          </div>
          <div class="md-form">
            <input
              mdbInput
              placeholder="Phone Number*"
              type="text"
              id="phoneNumber"
              class="form-control"
              formControlName="visitorPhoneNumber"
            />
            <label for="phoneNumber"></label>
          </div>
          <div class="md-form">
            <input
              mdbInput
              placeholder="Email address*"
              type="text"
              id="userEmail"
              class="form-control"
              formControlName="visitorEmail"
            />
            <label for="userEmail"></label>
          </div>
          <div class="md-form">
            <input
              mdbInput
              placeholder="Subject*"
              type="text"
              id="subject"
              class="form-control"
              formControlName="visitorMessageSubject"
            />
            <label for="subject"></label>
          </div>
        </div>
        <div class="user-detail">
          <div class="md-form">
            <textarea
              type="text"
              id="userMessage"
              class="md-textarea md-textarea-auto form-control"
              mdbInput
              formControlName="visitorMessage"
              placeholder="Write your message here*"
            ></textarea>
            <label for="userMessage"></label>
          </div>
        </div>
        <div class="submission">
          <button
            type="submit"
            mdbBtn
            class="bg-color w-50"
            mdbWavesEffect
            [disabled]="contactForm.invalid"
            (click)="basicModal.show()"
          >
            {{ buttonTxt }}
          </button>
        </div>
      </form>
    </div> -->
  </div>
  <div class="confirmation">
    <div
      mdbModal
      #basicModal="mdbModal"
      class="modal fade"
      tabindex="-1"
      role="dialog"
      aria-labelledby="myBasicModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog" role="document">
        <div class="modal-content bg-color">
          <div class="modal-header">
            <button
              type="button"
              class="close pull-right"
              aria-label="Close"
              (click)="basicModal.hide()"
            >
              <span aria-hidden="true">×</span>
            </button>
            <h4 class="modal-title w-100" id="myModalLabel">
              {{ acknowledgmentTtile }}
            </h4>
          </div>
          <div class="modal-body">
            {{ message }}
          </div>
          <div class="modal-footer">
            <button
              type="button"
              mdbBtn
              color="light"
              class="relative waves-light"
              (click)="basicModal.hide()"
              mdbWavesEffect
            >
              {{ acknowledgment }}
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
